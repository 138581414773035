import Core from 'sitelink-frontend-core';
import bundle from 'sitelink-frontend-plugin-bundle';
import datalogFiles from 'sitelink-frontend-plugin-datalog-files';
import delayReport from 'sitelink-frontend-plugin-delay-report';
import designData from 'sitelink-frontend-plugin-design-data';
import events from 'sitelink-frontend-plugin-events';
import files from 'sitelink-frontend-plugin-files';
import haulReport from 'sitelink-frontend-plugin-haul-report';
import legalEntity from 'sitelink-frontend-plugin-legal-entity';
import machineList from 'sitelink-frontend-plugin-machine-list';
import mapAsBuilt from 'sitelink-frontend-plugin-map-cesium-asbuilt';
import mapDesignData from 'sitelink-frontend-plugin-map-cesium-design-data';
import mapHauls from 'sitelink-frontend-plugin-map-cesium-hauls';
import mapImagery from 'sitelink-frontend-plugin-map-cesium-imagery';
import mapMachines from 'sitelink-frontend-plugin-map-cesium-machines';
import mapRegions from 'sitelink-frontend-plugin-map-cesium-regions';
import mapSites from 'sitelink-frontend-plugin-map-cesium-sites';
import mapView from 'sitelink-frontend-plugin-map-cesium';
import mapWeights from 'sitelink-frontend-plugin-map-cesium-weights';
import mfk from 'sitelink-frontend-plugin-mfk';
import notifications from 'sitelink-frontend-plugin-notifications';
import bottomPanel from 'sitelink-frontend-plugin-bottom-panel';
import rdm from 'sitelink-frontend-plugin-rdm';
import regions from 'sitelink-frontend-plugin-regions';
import remoteSupport from 'sitelink-frontend-plugin-remote-support';
import report from 'sitelink-frontend-plugin-report';
import sidebar from 'sitelink-frontend-plugin-sidebar';
import siteConnection from 'sitelink-frontend-plugin-site-connection';
import siteData from 'sitelink-frontend-plugin-site-data';
import siteLocalisation from 'sitelink-frontend-plugin-site-localisation';
import siteOwner from 'sitelink-frontend-plugin-site-owner';
import smartview from 'sitelink-frontend-plugin-smartview';
import smartviewHauls from 'sitelink-frontend-plugin-smartview-hauls';
import smartviewWeights from 'sitelink-frontend-plugin-smartview-weights';
import status from 'sitelink-frontend-plugin-status';
import toolbar from 'sitelink-frontend-plugin-toolbar';
import userPermissions from 'sitelink-frontend-plugin-user-permissions';
import userPreferences from 'sitelink-frontend-plugin-user-preferences';
import weightReport from 'sitelink-frontend-plugin-weight-report';
import whatsNew from 'sitelink-frontend-plugin-whats-new';

export const plugins = [
  bundle,
  datalogFiles,
  delayReport,
  designData,
  events,
  files,
  haulReport,
  legalEntity,
  machineList,
  mapAsBuilt,
  mapDesignData,
  mapHauls,
  mapImagery,
  mapMachines,
  mapRegions,
  mapSites,
  mapView,
  mapWeights,
  mfk,
  notifications,
  bottomPanel,
  rdm,
  regions,
  remoteSupport,
  report,
  sidebar,
  siteConnection,
  siteData,
  siteLocalisation,
  siteOwner,
  smartview,
  smartviewHauls,
  smartviewWeights,
  status,
  toolbar,
  userPermissions,
  userPreferences,
  weightReport,
  whatsNew,
];

global.SitelinkFrontend = {
  version: process.env.VERSION,
  core: {},
  init(options) {
    this.core = new Core({ ...options, plugins });
    this.core.render();
  },
};
